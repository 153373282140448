import React from 'react';
import { useOnboardContext, useAddress } from '../../../context/OnboardContext';
import styles from './WalletButton.module.css';
import { truncateWeb3Address } from '../../../services/onboard/helpers';
import iconClose from '../../../assets/monsters/images/icon_close.svg';
import { Link } from 'react-router-dom';

const WalletButton = ({ showProfile = true }) => {
  const { linkWallet, resetWallet } = useOnboardContext();
  const address = useAddress();

  return address ? (
    <div className={styles.btn_connected}>
      {showProfile ? (
        <Link to="/profile" className={styles.profile} title="Go to my profile">
          <span>{address && truncateWeb3Address(address)}</span>
        </Link>
      ) : (
        <span style={{ marginLeft: '12px' }}>{address && truncateWeb3Address(address)}</span>
      )}
      <button onClick={resetWallet} className={styles.btn_disconnect}>
        <img src={iconClose} alt="disconnect wallet" />
      </button>
    </div>
  ) : (
    <button onClick={linkWallet} className={styles.btn}>
      Connect Wallet
    </button>
  );
};

export default WalletButton;
