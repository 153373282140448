import React, { useEffect, useState } from 'react';
import styles from './Tabs.module.css';

const Tabs = ({ data, customStyle }) => {
  const [tabs, setTabs] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const activeTab = data.find((tabData) => {
      return tabData.state === 'active';
    });
    setTabs(data);
    setActiveTab(activeTab.id);
  }, [data]);

  const handleClick = (event, currentTab) => {
    event.preventDefault();
    const newTabs = tabs;
    newTabs.forEach((tab, index) => {
      newTabs[index].state = tab.id === currentTab.id ? 'active' : 'inactive';
    });
    setTabs(newTabs);
    setActiveTab(currentTab.id);
  };
  const renderTabs = () => {
    return (
      <ul id={styles.navTabs} className="nav">
        {tabs.map((tab, index) => {
          return (
            <li
              className={`${tab.id === activeTab ? styles.active : ''} text-center pl-2 pr-2`}
              key={index}
            >
              <a href="/" id={styles.navLink} onClick={(event) => handleClick(event, tab)}>
                {tab.imgIcon && (
                  <img className={styles.tabIcon} alt={tab.name} src={tab.imgIcon}></img>
                )}
                <p className={`${styles.tabTitle}fs-16`}>{tab.name}</p>
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const tabToShow = activeTab - 1;
  return (
    <div className={customStyle} id={styles.tabsWrapper}>
      {tabs && renderTabs()}
      {tabs && tabs[tabToShow].content()}
    </div>
  );
};

export default Tabs;
