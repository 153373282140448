import { Link } from 'react-router-dom';
import styles from './LargeBtn.module.css';

const LargeBtn = ({ typeButton, title, description, ctaText, icon, path, onClick }) => {
  /*
   * Component can accept a prop called    `typeButton` which defines
   * the type of element you will render: `button` HTML tag or `Link` component
   * It also accepts an onClick prop, that is used in the `button` HTML tag if needed
   * Otherwise if `typeButton` is `false` you have to pass a prop `path` to indicate navigation destination
   */

  return (
    <div id={styles.largeButton} className="mt-2">
      {typeButton ? (
        <button onClick={onClick} className={styles.btn}>
          <div className={styles.largeBtnDetailed}>
            {icon}
            <h1>{title}</h1>
            <p className={`mb-1 fs-16`}>{description}</p>
            {ctaText && (
              <span
                className={`${styles.readMoreText} d-block color-red text-right fs-16 mt-2 strong`}
              >
                {ctaText}
              </span>
            )}
          </div>
        </button>
      ) : (
        <Link to={path}>
          <div className={styles.largeBtnDetailed}>
            {icon}
            <h1>{title}</h1>
            <p className={`mb-1 fs-16`}>{description}</p>
            {ctaText && (
              <span className={`${styles.readMoreText} d-block color-red text-right fs-16 mt-2`}>
                {ctaText}
              </span>
            )}
          </div>
        </Link>
      )}
    </div>
  );
};

export default LargeBtn;
