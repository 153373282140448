import { useEffect } from 'react';
import { useState, useContext } from 'react';
import { createContext } from 'react';
import { collections as _collections } from '../data/localData';

export const MarketContext = createContext();

const MarketProvider = (props) => {
  const [currentDrop, setCurrentDrop] = useState(null);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [collections, setCollections] = useState([]);
  const [myBid, setMyBid] = useState(0);

  //get with user effect collections array
  useEffect(() => {
    setCollections(_collections);
  }, []);

  const updateDrop = (drop) => {
    setCurrentDrop(drop);
  };

  const updateCollection = (collection) => {
    setCurrentCollection(collection);
  };

  const updateMyBid = (_myBid) => {
    setMyBid(_myBid);
  };

  return (
    <MarketContext.Provider
      value={{
        collections,
        currentDrop,
        updateDrop,
        currentCollection,
        updateCollection,
        myBid,
        updateMyBid,
      }}
    >
      {props.children}
    </MarketContext.Provider>
  );
};

export default MarketProvider;

export const useMarket = () => {
  return useContext(MarketContext);
};
