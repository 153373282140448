import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styles from './SubcribeForm.module.css'

const SubscribeForm = () => {
    const url =
        "https://waytoodigital.us3.list-manage.com/subscribe/post?u=89e8f2761f4389e08d89a25b2&id=f6176a5c17";

    const CustomForm = ({ status, message, onValidated }) => {
        let email, name;
        const submit = () =>
            // email &&
            // name &&
            // email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
                FNAME: name.value
            });

        return (<>
            <div className={styles.suscribe_form}>
                <fieldset>
                    <input
                        ref={node => (name = node)}
                        type="text"
                        placeholder="Your name"
                    />
                    <input
                        ref={node => (email = node)}
                        type="email"
                        placeholder="Your email"
                    />
                </fieldset>
                <fieldset>
                    <button className={styles.suscription_submit} onClick={submit}>
                        Sign up
                    </button>
                </fieldset>
            </div>
            {/* {status === "sending" && <div style={{ color: "white", marginTop:'25px', textAlign:'center'  }}>Sending...</div>} */}
            {status === "error" && (
                <div
                    style={{ color: "red", marginTop: '25px', textAlign: 'right' }}
                    dangerouslySetInnerHTML={{ __html: `${message.charAt(0) === '0' ? message.substring(3) : message}` }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "white", marginTop: '25px', textAlign: 'right' }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
        </>
        );
    };

    return (
        <div id={styles.subscribe_form_container}>
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}

            />
        </div>
    )
}

export default SubscribeForm