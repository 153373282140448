import React from 'react';
import styles from './CountSelector.module.css';

const CountSelector = ({ handleCount, min, max, count }) => {
  const sumCount = () => {
    if (count < max) {
      handleCount(count + 1);
    }
  };
  const subCount = () => {
    if (count > min) {
      handleCount(count - 1);
    }
  };

  return (
    <div className={styles.count_wrapper}>
      <button disabled={count === min ? true : false} onClick={subCount}>
        -
      </button>
      <span>{count}</span>
      <button disabled={count === max ? true : false} onClick={sumCount}>
        +
      </button>
    </div>
  );
};

export default CountSelector;
