import { useState, useRef, useEffect } from 'react';
import styles from './ViewDetails.module.css';
import { useHistory } from 'react-router-dom';

function ViewDetails({ title, children, goToItem, id }) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');

  const content = useRef(null);

  const history = useHistory();

  useEffect(() => {
    setHeightState(setActive === '' ? '0px' : `${content.current.scrollHeight}px`);
  }, [setActive]);

  function toggleDetails() {
    setActiveState(setActive === '' ? 'active' : '');
  }

  const goToItemDetail = () => history.push(`/token/${id}`);

  return (
    <div className={styles.viewDetailsSection}>
      <button
        id={styles.viewDetails}
        className={`${setActive}`}
        onClick={goToItem ? goToItemDetail : toggleDetails}
      >
        {!setActive ? title : `Hide details`}
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        id={styles.viewDetailsContent}
        className="fs-7"
      >
        {children}
      </div>
    </div>
  );
}

export default ViewDetails;
