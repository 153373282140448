import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import imageLogoisomobile from '../../../assets/monsters/images/mc_iso.png';
import imageMenumobile from '../../../assets/monsters/images/menu_btn_mobile.svg';
import iconClose from '../../../assets/monsters/images/icon_close.svg';
import WalletButton from '../WalletButton/WalletButton';
import { Link as RouterLink } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { useAddress } from '../../../context/OnboardContext';

import { Link } from 'react-scroll';

const Header = () => {
  const [isScroll, setIsScroll] = useState(false);
  const [menuMobile, setMenuMobile] = useState(false);
  const address = useAddress();

  useEffect(() => {
    const handleScroll = () => {
      setIsScroll(window.scrollY > 120);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header id={styles.header} className={isScroll ? 'header_scroll' : ''}>
        <Link
          id={styles.isomobile}
          className="mobile_block"
          to="header"
          spy={true}
          smooth={true}
          duration={500}
        >
          <img src={imageLogoisomobile} alt="Monster Chompers" />
        </Link>
        <nav>
          <Link
            id={styles.isodesktop}
            className=""
            to="header"
            spy={true}
            smooth={true}
            duration={500}
          >
            <img src={imageLogoisomobile} alt="Monster Chompers" />
          </Link>
          <ul>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="mint"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Mint
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="menu"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Our Menu
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="roadmap"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Roadmap
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="team"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="faq"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                FAQ
              </Link>
            </li>
          </ul>
        </nav>

        <div className={styles.actions}>
          {address && (
            <RouterLink to={'/profile'}>
              <FaUserAlt />
              <span>My Profile</span>
            </RouterLink>
          )}

          <WalletButton />
        </div>
        <img
          src={imageMenumobile}
          alt="Mobile menu button"
          className={`${styles.icon_close} mobile_block`}
          onClick={() => setMenuMobile(true)}
        />
      </header>
      <div id={styles.menu_mobile} className={menuMobile ? styles.active : ''}>
        <img
          id={styles.close_menu}
          src={iconClose}
          alt="Close menú"
          onClick={() => setMenuMobile(false)}
        />
        <nav>
          <ul>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="mint"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                Mint
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="menu"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                Our Menu
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="roadmap"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                Roadmap
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="team"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuMobile(false)}
                activeClass="active"
                className="anchor_link"
                to="faq"
                spy={true}
                smooth={true}
                duration={500}
                offset={-65}
              >
                Faq
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
