import React from 'react';
import styles from './Chomp.module.css';
import imageMonster1 from '../../../assets/monsters/images/monster_chomp_1.png';
import imageMonster2 from '../../../assets/monsters/images/monster_chomp_2.png';
import imageMonstermobile from '../../../assets/monsters/images/monster_chomp_mobile.png';
import { Link } from 'react-scroll';

const Chomp = () => {
  return (
    <section id={styles.chomp}>
      <div className="grid">
        <div className="col_4 mobile_none">
          <img src={imageMonster1} alt="Monster" />
        </div>
        <div id={styles.txt_chomp} className="col_4">
          <h2>
            Are you ready to<span> chomp?</span>
          </h2>
          <Link className="btn" to="mint" spy={true} smooth={true} duration={500} offset={-90}>
            Mint now!
          </Link>
        </div>
        <div className="col_4 mobile_none">
          <img src={imageMonster2} alt="Monster" />
        </div>
        <div className="col_12 mobile_block">
          <img src={imageMonstermobile} alt="Monster" />
        </div>
      </div>
    </section>
  );
};

export default Chomp;
