import React from 'react';
import styles from './Menu.module.css';
import imageFood1 from '../../../assets/monsters/images/food1.png';
import imageFood2 from '../../../assets/monsters/images/food2.png';
import imageFood3 from '../../../assets/monsters/images/food3.png';
import imageFood4 from '../../../assets/monsters/images/food4.png';
import imageFood5 from '../../../assets/monsters/images/food5.png';
import imageFood6 from '../../../assets/monsters/images/food6.png';
import imageFood7 from '../../../assets/monsters/images/food7.png';
import imageFood8 from '../../../assets/monsters/images/food8.png';
import imageFood9 from '../../../assets/monsters/images/food9.png';
import imageFood10 from '../../../assets/monsters/images/food10.png';
import imageFood11 from '../../../assets/monsters/images/food11.png';
import imageFood12 from '../../../assets/monsters/images/food12.png';
import imageFood13 from '../../../assets/monsters/images/food13.png';
import imageFood14 from '../../../assets/monsters/images/food14.png';

import Slider from 'react-slick';

const Menu = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section id={styles.menu}>
      <div className="inner">
        <h2>
          Our <span className="red">Menu</span>
        </h2>
        <p className={styles.txt}>
          If you want a taste of something terrifying, Monster Chompers All-Nite Eatery has it
          sizzling on the stove.
        </p>
        <div id={styles.menu_slider}>
          <Slider {...settings}>
            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood1} alt="Food" />
                </div>
                <h3>unicorn poppers</h3>
                <p className={styles.info_food}>
                  Made with real Unicorn meat and sprinkled with fairy dust.
                </p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood2} alt="Food" />
                </div>
                <h3>Frozen Brains</h3>
                <p className={styles.info_food}>
                  Our brains are hand picked daily from the local morgue and served chilled..
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood3} alt="Food" />
                </div>
                <h3>Barbequed Bat</h3>
                <p className={styles.info_food}>
                  Marinated in fresh Vampire's blood and grilled to perfection.
                </p>
              </div>

              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood4} alt="Food" />
                </div>
                <h3>Fried Piranha</h3>
                <p className={styles.info_food}>
                  Wild caught. Betcha can't eat just one. There's a whole school where this came
                  from.
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood5} alt="Food" />
                </div>
                <h3>Rancid Ratatouille</h3>
                <p className={styles.info_food}>Perfectly pungent! Aged 7 weeks after death.</p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood14} alt="Food" />
                </div>
                <h3>Candied Voodoo Dolls</h3>
                <p className={styles.info_food}>
                  Revenge is sweet! Causing harm to your enemies never tasted so good. Watch out for
                  those pesky pins as they go down the hatch.
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood6} alt="Food" />
                </div>
                <h3>Sugar-Coated Spider</h3>
                <p className={styles.info_food}>
                  Served alive and these creeps still crawl. Please keep your arachnids on your own
                  table.
                </p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood7} alt="Food" />
                </div>
                <h3>Delicious Death Head Moth</h3>
                <p className={styles.info_food}>
                  Ahh... just like Grand-monster used to make! Ancient homemade recipe.
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood8} alt="Food" />
                </div>
                <h3>Spaghetti & Eyeballs</h3>
                <p className={styles.info_food}>
                  Our eyeballs ooze with flavor and pus with every bite!{' '}
                  <span className="mobile_none">
                    This world famous dish is well worth the mess.
                  </span>
                </p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood9} alt="Food" />
                </div>
                <h3>Giant Centipede & Broccoli</h3>
                <p className={styles.info_food}>
                  For you health food freaks, these aromatic arthropods are sautéed in their own
                  venom, so these crunchy critters pack a real punch!
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood10} alt="Food" />
                </div>
                <h3>severed finger a la mode</h3>
                <p className={styles.info_food}>
                  Each finger-licking bite includes bonus fingernails for added flavor.
                </p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood11} alt="Food" />
                </div>
                <h3>Sinsacious Soft Serve</h3>
                <p className={styles.info_food}>
                  Save room for dessert! Be sure to take the first bite from this fierce frozen
                  favorite, because they certainly bite back.
                </p>
              </div>
            </div>

            <div className={styles.slide_menu}>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood12} alt="Food" />
                </div>
                <h3>
                  <span className="mobile_none">Spicy</span> Shrunken Heads Skewers
                </h3>
                <p className={styles.info_food}>
                  These tasty tribal delicacies have the souls of the dead trapped inside.
                </p>
              </div>
              <div className="col_6">
                <div className={styles.img_wrapper}>
                  <img src={imageFood13} alt="Food" />
                </div>
                <h3>Charbroiled Troll Feet</h3>
                <p className={styles.info_food}>
                  Served bone-in and glazed with our special swamp stock, these meat-lovers delights
                  are back on the menu after a long hiatus.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Menu;
