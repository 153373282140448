import React from 'react';
import styles from './Roadmap.module.css';
import imageWall from '../../../assets/monsters/images/monster_wall.jpg';
import imageMilestone1 from '../../../assets/monsters/images/milestone1.png';
import imageMilestone2 from '../../../assets/monsters/images/milestone2.png';
import imageMilestone3 from '../../../assets/monsters/images/milestone3.png';
import imageLine from '../../../assets/monsters/images/line_roadmap.png';

const Roadmap = () => {
  return (
    <section id={styles.roadmap} className="bg">
      <div className="inner">
        <h2>Roadmap</h2>
        <h3>
          A long and <span className="red">yummy</span> journey
        </h3>

        <div id={styles.milestones} className="grid">
          <img id={styles.line} src={imageLine} alt="Line" />
          <div className="col_4">
            <div className={styles.img_wrapper}>
              <img src={imageMilestone1} alt="1/1 Monsters Airdrop" />
            </div>
            <div className={styles.circle}></div>
            <strong className={styles.percent}>25%</strong>
            <h4>Monsters Airdrop</h4>
            <p>5 holders will be airdropped with ultra-rare 1/1 NFTs by Alan Robert.</p>
          </div>
          <div className="col_4">
            <div className={styles.img_wrapper}>
              <img src={imageMilestone2} alt="Franchise Building" />
            </div>
            <div className={styles.circle}></div>
            <strong className={styles.percent}>75%</strong>
            <h4>franchise building</h4>
            <p>Collect, build your franchise and start generating income!</p>
          </div>
          <div className="col_4">
            <div className={styles.img_wrapper}>
              <img src={imageMilestone3} alt="Dao Graphic Novel" />
            </div>
            <div className={styles.circle}></div>
            <strong className={styles.percent}>100%</strong>
            <h4>dao graphic novel </h4>
            <p>A graphic novel where the community plays a fundamental role.</p>
          </div>
        </div>
      </div>

      <div id={styles.monster_wall}>
        <img src={imageWall} alt="Monster Chompers wall" />
        <div className={styles.overlay}></div>
      </div>
    </section>
  );
};

export default Roadmap;
