import { useEffect, useCallback, useState } from 'react';
// import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useWalletProvider, useAddress } from '../context/OnboardContext';
import ProfileBanner from '../components/Monsters/ProfileBanner/ProfileBanner';
import styles from './UserProfile.module.css';
import HeaderBack from '../components/Monsters/Header/HeaderBack';
import { factoryAddress, factoryAbi } from '../services/onboard/contract';
import Web3 from 'web3';
import axios from 'axios';
import DropCard from '../components/Monsters/DropCard/DropCard';
import icon_loading from '../assets/monsters/images/loading.svg';

const Profile = () => {
  //   const history = useHistory();
  const { userAddress } = useParams();
  const address = useAddress();
  const provider = useWalletProvider();
  const currentProfile = userAddress ? userAddress : address;

  const [loading, setLoading] = useState(false);
  const [tokensArr, setTokensArr] = useState([]);

  const getTokens = useCallback(async () => {
    try {
      setLoading(true);
      const web3 = new Web3(provider);
      const myContract = new web3.eth.Contract(factoryAbi, factoryAddress);
      const tokensCount = await myContract.methods.balanceOf(currentProfile).call();
      const tokensMetadata = [];
      for (let index = 0; index < tokensCount; index++) {
        const tokenId = await myContract.methods.tokenOfOwnerByIndex(currentProfile, index).call();
        const ipfsUri = await myContract.methods.tokenURI(tokenId).call();
        const ipfsUriHttp = ipfsUri.substring(6);
        const metadata = await axios.get(`https://ipfs.io/ipfs/${ipfsUriHttp}`);
        tokensMetadata.push({ ...metadata.data, tokenId });
      }
      setTokensArr(tokensMetadata);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [provider, currentProfile]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (address) {
      getTokens();
    }
  }, [address, getTokens]);

  return (
    <div className="px-2 pt-5">
      <HeaderBack />
      <h1 className={styles.heading_profile}>my nfts</h1>
      <ProfileBanner wallet={currentProfile}></ProfileBanner>
      {address &&
        (!loading ? (
          tokensArr.length > 0 ? (
            <div className={styles.ownedItemWrapper}>
              {tokensArr.map(({ name, image, alt_image, attributes, tokenId }) => (
                <DropCard
                  key={name}
                  title={name}
                  image={`https://ipfs.io/ipfs/${image.substring(6)}`}
                  details={attributes}
                  id={tokenId}
                ></DropCard>
              ))}
            </div>
          ) : (
            <div className={styles.loading_wrapper}>
              <p>No NFTs found for this wallet...</p>
            </div>
          )
        ) : (
          <div className={styles.loading_wrapper}>
            <img src={icon_loading} alt="Loading" />
          </div>
        ))}
    </div>
  );
};

export default Profile;
