export const CURRENT_ENV = process.env.REACT_APP_CUSTOM_NODE_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const WEB3_PROVIDER = process.env.REACT_APP_INFURA_WEB3_PROVIDER;
export const FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY;
export const DAPP_ID_KEY = process.env.REACT_APP_DAPP_ID_KEY;
export const NETWORK_ID = process.env.REACT_APP_NETWORK_ID;
export const CONTRACT_ADDRESS = process.env.REACT_APP_FACTORY_ADDRESS;
export const MINT_ENABLED = process.env.REACT_APP_MINT_ENABLED;
export const WHITELIST_OPEN = process.env.REACT_APP_WHITELIST_OPEN;
export const MINT_OPEN = process.env.REACT_APP_MINT_OPEN;
