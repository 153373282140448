//reviewing de data model
export const collections = [
  {
    id: 1,
    type: 'collection',
    name: 'Collection 1',
    createdDate: '2021-09-15T27:00:00.013Z',
    dueDate: '2021-10-28T27:00:00.013Z',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium  totam.',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
  {
    id: 2,
    type: 'collection',
    name: 'Collection 2',
    createdDate: '2021-09-15T27:00:00.013Z',
    dueDate: '2021-10-28T27:00:00.013Z',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium  totam.',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
  {
    id: 3,
    type: 'collection',
    name: 'Collection 3',
    createdDate: '2021-09-15T27:00:00.013Z',
    dueDate: '2021-10-28T27:00:00.013Z',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium  totam.',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
];

export const drops = [
  {
    id: 1,
    collectionId: 1,
    type: 'drops',
    name: 'Assemblage',
    tag: 'Unique',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.',
    fixedPrice: true,
    createdDate: '2021-10-09T27:00:00.013Z',
    dueDate: '2021-10-30T27:00:00.013Z',
    price: '0.32',
    bid: '0',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    sold: false,
    details: {
      collection: '100',
      sizeFile: '800x800px',
      fileType: 'jpg',
    },
    address:'0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c1'
  },
  {
    id: 2,
    collectionId: 1,
    type: 'drops',
    name: 'Treasure Falls',
    tag: 'Super Rare',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.',
    fixedPrice: false,
    price: '0.2',
    bid: '0.8',
    createdDate: '2021-09-09T27:00:00.013Z',
    dueDate: '2021-10-31T27:00:00.013Z',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    sold: false,
    details: {
      collection: '100',
      sizeFile: '800x800px',
      fileType: 'jpg',
    },
    address:'0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c2'
  },
  {
    id: 3,
    collectionId: 1,
    type: 'drops',
    name: 'All you need is love',
    tag: 'Unique',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.',
    fixedPrice: true,
    price: '0.10',
    bid: '0',
    createdDate: '2021-10-09T27:00:00.013Z',
    dueDate: '2021-10-29T27:00:00.013Z',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    sold: true,

    details: {
      collection: '100',
      sizeFile: '800x800px',
      fileType: 'jpg',
    },
    address:'0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c3'
  },
];

export const dropOwners = [
  {
    id: 1,
    dropId: 1,
    owners: [
      {
        id: 1,
        user: 2,
      },
    ],
  },
  {
    id: 2,
    dropId: 2,
    owners: [
      {
        id: 1,
        user: 1,
      },
    ],
  },
  {
    id: 3,
    dropId: 3,
    owners: [
      {
        id: 1,
        user: 2,
      },
    ],
  },
];

export const dropHistory = [
  {
    id: 1,
    dropId: 2,
    history: [
      {
        id: 0,
        price: '0.32',
        user: 1,
        createdDate: '2020-09-02T19:00:00.013Z',
      },
      {
        id: 1,
        price: '0.5',
        user: 2,
        createdDate: '2020-09-03T19:00:00.013Z',
      },
    ],
  },
  {
    id: 2,
    dropId: 1,
    history: [
      {
        id: 0,
        price: '0.32',
        user: 1,
        createdDate: '2020-09-02T19:00:00.013Z',
      },
    ],
  },
  {
    id: 3,
    dropId: 3,
    history: [
      {
        id: 0,
        price: '0.32',
        user: 1,
        createdDate: '2020-09-02T19:00:00.013Z',
      },
    ],
  },
];

export const users = [
  {
    id: 1,
    name: 'John Doe',
    username: 'johndoe',
    avatar:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    address: '0x0001',
  },
  {
    id: 2,
    name: 'Johanna Doha',
    username: 'johnna',
    avatar:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    address: '0x0002',
  },
  {
    id: 3,
    name: 'Foo Fighters',
    username: 'ff',
    avatar:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    address: '0x0003',
  },
];
