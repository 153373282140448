import React from 'react';
import styles from './Marquee.module.css';
import imageAvatar1 from '../../../assets/monsters/images/avatar1.jpg';
import imageAvatar2 from '../../../assets/monsters/images/avatar2.jpg';
import imageAvatar3 from '../../../assets/monsters/images/avatar3.jpg';
import imageAvatar4 from '../../../assets/monsters/images/avatar4.jpg';
import imageAvatar5 from '../../../assets/monsters/images/avatar5.jpg';
import imageAvatar6 from '../../../assets/monsters/images/avatar6.jpg';

const Marquee = () => {
  const images = [
    imageAvatar1,
    imageAvatar2,
    imageAvatar3,
    imageAvatar4,
    imageAvatar5,
    imageAvatar6,
    imageAvatar1,
    imageAvatar2,
    imageAvatar3,
    imageAvatar4,
    imageAvatar5,
    imageAvatar6,
  ];

  return (
    <div className={styles.marquee}>
      <div className={styles.marquee_content}>
        {images.map((image, index) => (
          <div key={index} className={styles.marquee_item}>
            <img src={image} alt={`avatar ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
