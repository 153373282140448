import { useState } from 'react';
import BasicContentBlock from '../../components/Monsters/Onboarding/BasicContentBlock';
import SecurityIcon from '../../assets/monsters/images/security_icon.png';
import Modal from '../../components/Monsters/Onboarding/Modal';
import './WelcomePage.css';
import Input from '../../components/Monsters/Onboarding/Input';
import Checkbox from '../../components/Monsters/Onboarding/Checkbox';

const WelcomePage = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (index) => {
    setShowModal(!showModal);
  };

  return (
    <div className="container-fluid">
      <BasicContentBlock
        title="Welcome to..."
        text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem."
      />
      <div className="info-items-wrapper">
        <div className="info-item my-4 px-2">
          <div className="title-wrapper">
            <img alt="" className="d-inline-block" src={SecurityIcon}></img>
            <h1 className="d-inline-block fs-16 ml-1">Lorem ipsum dolor</h1>
          </div>
          <p className="ml-1 fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div className="info-item my-4 px-4">
          <div className="title-wrapper">
            <img alt={`Security`} className="d-inline-block" src={SecurityIcon}></img>
            <h1 className="d-inline-block fs-16 ml-1">Lorem ipsum dolor</h1>
          </div>
          <p className="ml-1 fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div className="info-item my-4 px-4">
          <div className="title-wrapper">
            <img alt={`Security`} className="d-inline-block" src={SecurityIcon}></img>
            <h1 className="d-inline-block fs-16 ml-1">Lorem ipsum dolor</h1>
          </div>
          <p className="ml-1 fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>

      <button className="mt-4 btn btn-dark text-center fw-bold w-100 py-2" onClick={toggleModal}>
        Go to complete my profile
      </button>

      <button
        className="mt-4 btn btn-light border-transparent text-center fw-bold w-100 py-2"
        onClick={toggleModal}
      >
        Set up later
      </button>
      <Modal showModal={showModal} styled="mt-5" setShowModal={setShowModal} onClick={toggleModal}>
        <BasicContentBlock
          title="Complete your profile"
          text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem."
        />
        <Input placeholder="@johndoe" inputTitle="Username" styled="w-100"></Input>
        <Input placeholder="mail@gmail.com" inputTitle="Email" styled="w-100"></Input>
        <Checkbox id="agreement" text="I agree to the Privacy Policy and Terms of Service." />
        <Checkbox id="subscribe" text="I opt in to receive offers from XXXXX to my email address" />
        <button onClick={toggleModal} className="mt-4 btn btn-dark text-center fw-bold w-100 py-2">
          Save
        </button>
      </Modal>
    </div>
  );
};

export default WelcomePage;
