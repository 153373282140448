import styles from './Button.module.css';

const Button = ({ children, onClick, type = 'button', disabled, styled = 'btnDark' }) => {
  return (
    <button
      id={styled === 'btnDark' ? styles.btnDark : styles.btnLight}
      className={`btn ${styled} w-100 py-2`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
