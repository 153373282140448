import './Input.css';

const Input = ({
  children,
  onClick,
  type = 'button',
  styled = 'input-light',
  placeholder = '',
  inputTitle = '',
}) => {
  return (
    <div className="mt-3 input-component">
      <h1 className="fs-14 fw-bold">{inputTitle}</h1>
      <input
        className={`input ${styled} py-2`}
        type={type}
        onClick={onClick}
        placeholder={placeholder}
      >
        {children}
      </input>
    </div>
  );
};

export default Input;
