import styles from './HeaderBack.module.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import imageLogoisomobile from '../../../assets/monsters/images/mc_iso.png';

import { Link } from 'react-scroll';

const Header = () => {
  const history = useHistory();
  const backToHome = () => {
    history.push(`/`);
  };
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScroll(window.scrollY > 120);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header id={styles.header} className={isScroll ? 'header_scroll' : ''}>
        <Link
          id={styles.isomobile}
          className="mobile_block"
          to="header"
          spy={true}
          smooth={true}
          duration={500}
          onClick={backToHome}
        >
          <img src={imageLogoisomobile} alt="Monster Chompers" />
        </Link>
        <nav>
          <Link
            id={styles.isodesktop}
            className=""
            to="header"
            spy={true}
            smooth={true}
            onClick={backToHome}
            duration={500}
          >
            Back to home
          </Link>
        </nav>
      </header>
    </>
  );
};

export default Header;
