import React from 'react';
import Header from '../components/Monsters/Header/Header';
import Hero from '../components/Monsters/Hero/Hero';
import MintContainer from '../components/Monsters/MintContainer/MintContainer';
import About from '../components/Monsters/About/About';
import Franchises from '../components/Monsters/Franchises/Franchises';
import Transmedia from '../components/Monsters/Transmedia/Transmedia';
import Video from '../components/Monsters/Video/Video';
import Press from '../components/Monsters/Press/Press';
import Menu from '../components/Monsters/Menu/Menu';
import Roadmap from '../components/Monsters/Roadmap/Roadmap';
import Team from '../components/Monsters/Team/Team';
import Faq from '../components/Monsters/Faq/Faq';
import Subscribe from '../components/Monsters/Subscribe/Subscribe';
import Chomp from '../components/Monsters/Chomp/Chomp';
import Footer from '../components/Monsters/Footer/Footer';
import { Element } from 'react-scroll';

import '../assets/monsters/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Monsters = () => {
  return (
    <div id="monsters_wrapper">
      <Element name="header" className="element">
        <Header />
      </Element>

      <Hero />

      <Element name="mint" className="element">
        <MintContainer />
      </Element>

      <Element name="about" className="element">
        <About />
      </Element>
      <Franchises />
      <Transmedia />

      <Element name="menu" className="element">
        <Menu />
      </Element>

      <Element name="roadmap" className="element">
        <Roadmap />
      </Element>

      <Element name="team" className="element">
        <Team />
      </Element>

      <Video />
      <Press />

      <Element name="faq" className="element">
        <Faq />
      </Element>

      <Element name="subscribe" className="element">
        <Subscribe />
      </Element>

      <Chomp />

      <Footer />
    </div>
  );
};

export default Monsters;
