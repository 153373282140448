import styles from './DropCard.module.css';
import ViewDetails from '../ViewDetails/ViewDetails';
import DetailPill from '../DetailPill/DetailPill';

const DropCard = ({ image, title, details, id }) => {
  return (
    <div className={styles.dropWrapper}>
      <div className="mb-2" id={styles.dropImageCard}>
        <img height="230" src={image} alt="" />
      </div>
      {title && <h3>{title}</h3>}
      <ViewDetails title="View details" goToItem={true} id={id}>
        <div>
          {' '}
          <DetailPill details={details} />
        </div>
      </ViewDetails>
    </div>
  );
};

export default DropCard;
