import React from 'react';
import styles from './About.module.css';
import imageWaitress from '../../../assets/monsters/images/monster_waitress.png';

const About = () => {
  return (
    <section id={styles.about} className="bg">
      <div className="inner">
        <div>
          <div>
            <h2>
              <span>Meet the</span>
              <strong className="red">Chompers</strong>
            </h2>
            <div className={styles.txt}>
              <p>
                A mysterious company run by monsters named WGC (Wallachia Gourmet Catering) targets
                and hijacks human restaurants in the middle of the night to host their own
                exclusive, pop-up banquets called{' '}
                <strong>Monster Chompers All-Nite Eateries</strong>. Vampires, Werewolves, Zombies,
                and more, travel far and wide from all over the metaverse to line up to taste the
                outlawed delicacies.
              </p>
              <p>
                Limited NFT collection featuring 10,000 unique digital collectables illustrated by{' '}
                <strong>
                  bestselling Beauty of Horror creator and Life of Agony bassist Alan Robert
                </strong>
                .
              </p>
            </div>
          </div>
          <div id={styles.img}>
            <img src={imageWaitress} alt="Monster Waitress" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
