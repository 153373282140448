import styles from './Card.module.css';

const Card = ({ label, imageLogo, title, text, subtitle, children }) => {
  return (
    <div className={styles.cardWrapper}>
      {label ? (
        <div id={styles.label} className="fs-16 fw-bold bg-violet">
          {label}
        </div>
      ) : null}
      {subtitle && <h5 className="uppercase fs-7 my-1">{subtitle}</h5>}
      <h1 className={styles.detailTitle}>
        {imageLogo ? <img src={imageLogo} alt={title} className="pr-1 d-inline-block" /> : null}
        {title}
      </h1>
      <p className="fs-16 my-1">{text}</p>
      {children}
    </div>
  );
};

export default Card;
