import React, { useState, useEffect } from 'react';
import styles from './Mint.module.css';
import imageMonsterMint from '../../../assets/monsters/images/mc_iso.png';
import axios from 'axios';
import { useOnboardContext, useAddress, useWalletProvider } from '../../../context/OnboardContext';
import CountSelector from '../CountSelector/CountSelector';
import icon_success from '../../../assets/monsters/images/icon_success.svg';
import icon_failed from '../../../assets/monsters/images/icon_failed.svg';
import icon_loading from '../../../assets/monsters/images/loading.svg';
import Web3 from 'web3';
import { factoryAddress, factoryAbi } from '../../../services/onboard/contract';
import { API_URL, MINT_OPEN, NETWORK_ID } from '../../../config';
import { useHistory } from 'react-router-dom';

const transactionIcons = {
  loading: icon_loading,
  success: icon_success,
  failed: icon_failed,
};

const Mint = () => {
  const [count, setCount] = useState(1);
  const [price] = useState(0.05);
  const [userConfirmation, setUserConfirmation] = useState(`loading`);
  const [blChainConfirmation, setBlChainConfirmation] = useState(`loading`);
  const [hash, setHash] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [notInWhiteList, setNotInWhiteList] = useState(false);

  const [minting, setMinting] = useState(false);
  const { linkWallet } = useOnboardContext();
  const address = useAddress();
  const provider = useWalletProvider();

  const history = useHistory();

  const handleCount = (countValue) => setCount(countValue);

  const handleGoToProfile = () => history.push('/profile');

  const handleError = (error) => {
    setUserConfirmation(`failed`);
    setBlChainConfirmation(`failed`);
    setError(true);
    setErrorMsg(error.message);
    return false;
  };

  const handleRestoreUi = () => {
    setMinting(false);
    setUserConfirmation(`loading`);
    setBlChainConfirmation(`loading`);
    setError(false);
    setSuccess(false);
    setNotInWhiteList(false);
    setHash(null);
  };

  useEffect(() => {
    handleRestoreUi();
  }, [address]);

  const mintNft = async () => {
    handleRestoreUi();
    try {
      setMinting(true);
      const web3 = new Web3(provider);
      const myContract = new web3.eth.Contract(factoryAbi, factoryAddress);

      //Check if whitelist is required
      const merkelEnabled = await myContract.methods
        .merkleEnabled()
        .call()
        .catch(function (error) {
          console.log(error);
          return false;
        });

      let mintParams = {
        proof: ['0x0000000000000000000000000000000000000000'],
        leaf: '0x0000000000000000000000000000000000000000',
        count,
      };

      if (merkelEnabled) {
        const addressData = await axios.get(`${API_URL}/whitelist/${address}`);
        if (addressData.data) {
          const { proof, leaf } = addressData.data;
          mintParams = {
            ...mintParams,
            proof,
            leaf,
          };
        } else {
          setNotInWhiteList(true);
          return false;
        }
      }

      const total = parseInt(count) * parseFloat(price);
      const totalFixed = parseFloat(total.toFixed(2));
      const valueEth = web3.utils.toWei(`${totalFixed}`, 'ether');

      await myContract.methods
        .mint(mintParams.proof, mintParams.leaf, parseInt(mintParams.count))
        .send({ from: address, value: valueEth })
        .once('transactionHash', function (hash) {
          setUserConfirmation(`success`);
          setHash(hash);
        })
        .once('receipt', function (receipt) {
          console.log('3 receipt', receipt);
          setBlChainConfirmation(`success`);
          setTimeout(() => {
            setSuccess(true);
          }, 1000);
        })
        .on('error', function (error, receipt) {
          handleError(error);
        });
    } catch (error) {
      console.log('error catched', error);
      handleError(error);
    }
  };

  return (
    <div id={styles.mint_module}>
      {MINT_OPEN === 'true' ? (
        <>
          <h2>How hungry are you?</h2>
          {minting ? (
            <div className={styles.minting_process}>
              <p>{`${count}x Monster Chomper${count > 1 ? `s` : ``}`}</p>
              <p className={styles.minting_total}>
                <span>Total</span> <span>{(price * count).toFixed(2)} ETH</span>
              </p>
              <div className={styles.sub_title_wrapper}>
                <h3>Minting Process</h3>
                {hash && (
                  <a
                    className={styles.view_etherscan}
                    href={`https://${
                      NETWORK_ID === '4' ? `rinkeby.etherscan.io` : `etherscan.io`
                    }/tx/${hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Etherscan
                  </a>
                )}
              </div>
              <ul>
                {!notInWhiteList && (
                  <>
                    <li>
                      Waiting for user confirmation{' '}
                      <img src={transactionIcons[userConfirmation]} alt="User confirmation" />
                    </li>
                    <li>
                      Waiting for blockchain confirmation{' '}
                      <img
                        src={transactionIcons[blChainConfirmation]}
                        alt="Blockchain confirmation"
                      />
                    </li>
                  </>
                )}
                {success && (
                  <>
                    <li>
                      Transaction confirmed{' '}
                      <img src={transactionIcons[`success`]} alt="Status confirmation" />
                    </li>
                    <li>
                      <button className={styles.btn_main} onClick={handleRestoreUi}>
                        Grab More
                      </button>
                      <button
                        className={styles.btn_main}
                        style={{ marginLeft: '10px' }}
                        onClick={handleGoToProfile}
                      >
                        Go to profile
                      </button>
                    </li>
                  </>
                )}
                {error && (
                  <>
                    <li>
                      Transaction failed{' '}
                      <img src={transactionIcons[`failed`]} alt="Status failed" />
                    </li>
                    <li>
                      <p style={{ fontSize: '12px' }}>{errorMsg}</p>
                    </li>
                  </>
                )}
                {notInWhiteList && (
                  <>
                    <li>
                      Not in whitelist{' '}
                      <img src={transactionIcons[`failed`]} alt="Status failed whitelist" />
                    </li>
                    <li>
                      <p className={styles.not_in_whitelist_text}>
                        You're not in the whitelist, check back later when public minting starts.
                      </p>
                    </li>
                  </>
                )}
              </ul>
            </div>
          ) : (
            <>
              <div className={styles.price}>
                <div>
                  <img src={imageMonsterMint} alt="Monster illustration" />
                  <div>
                    <span>Price per Chomper</span>
                    <strong>0.05 ETH</strong>
                  </div>
                </div>
                <CountSelector handleCount={handleCount} min={1} max={10} count={count} />
              </div>
              <div className={styles.submit}>
                <div className={styles.total}>
                  <p>
                    Total <span>{(price * count).toFixed(2)} ETH</span>
                  </p>
                </div>
                {address ? (
                  <button className={styles.btn_main} onClick={mintNft}>
                    Mint now!
                  </button>
                ) : (
                  <button className={styles.btn_main} onClick={linkWallet}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <h2>Mint closed</h2>
          <p className={styles.mint_close_text}>
            Mint is now closed, check back later for the public minting phase.
          </p>
        </>
      )}
    </div>
  );
};

export default Mint;
