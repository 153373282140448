import styles from './BasicContent.module.css';

const BasicContentBlock = ({ title, text, video, subtitle, poster }) => {
  return (
    <div id={styles.basicContentWrapper}>
      <h1 className="font-carnival">{title}</h1>
      <p className="mt-1 mb-3 w-80 ml-auto mr-auto">{text}</p>
      {video && (
        <video className="pb-2" width="100%" poster={poster} controls>
          <source src={video} />
        </video>
      )}
      {subtitle && <h5 className="mb-4 fs-20">{subtitle}</h5>}
    </div>
  );
};

export default BasicContentBlock;
