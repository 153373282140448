import React, { useState } from 'react';
import styles from './Video.module.css';
import videoAlan from '../../../assets/monsters/video/video.mp4';
import imagePoster from '../../../assets/monsters/images/poster.jpg';
import imageAlan1 from '../../../assets/monsters/images/alan1.jpg';
import imageAlan2 from '../../../assets/monsters/images/alan2.jpg';
import imageAlan3 from '../../../assets/monsters/images/alan3.jpg';

const Video = () => {
  const [showExtraBio, setShowExtraBio] = useState(false);
  return (
    <section id={styles.video} className="bg">
      <div className="inner">
        <h2>
          <strong className="red">Alan Robert</strong> and his universe
        </h2>
        <div id={styles.video_wrapper} className="video">
          <video controls poster={imagePoster}>
            <source src={videoAlan} type="video/mp4" />
          </video>
        </div>
        <div id={styles.alan_bio}>
          <p>
            Alan Robert is best known for being the bassist and primary-songwriter of the popular
            New York-based band Life of Agony for over three decades. The group's River Runs Red
            debut was hailed by Rolling Stone as one of the "Greatest Metal Albums of All Time".
            Life of Agony has released six studio albums to date, and has sold over one million
            records worldwide.
          </p>
          <div id={styles.bio_extra} className={showExtraBio ? styles.active : ''}>
            <p>
              A new, full-length documentary about the band titled{' '}
              <a href="http://www.soundofscars.com" target="_blank" rel="noreferrer">
                The Sound of Scars
              </a>{' '}
              will be released in early 2022. Outside of music, Alan Robert is a prolific artist and
              visual storyteller, named “The King of Horror Coloring Books” by{' '}
              <a href="https://bit.ly/3AE5BCa" target="_blank" rel="noreferrer">
                Revolver Magazine
              </a>
              . In 2016, his hit adult coloring book series The Beauty of Horror exploded on the
              scene and immediately became an Amazon #1 bestseller. It was featured in{' '}
              <a href="https://www.wsj.com/articles/BL-SEB-97154" target="_blank" rel="noreferrer">
                The Wall Street Journal
              </a>{' '}
              and{' '}
              <a href="https://bit.ly/3CFuRbW" target="_blank" rel="noreferrer">
                GQ
              </a>
              .
            </p>
            <div id={styles.alan_images} className="grid">
              <img src={imageAlan1} className="col_4" alt="Alan Robert 1" />
              <img src={imageAlan2} className="col_4 mobile_none" alt="Alan Robert 2" />
              <img src={imageAlan3} className="col_4 mobile_none" alt="Alan Robert 3" />
            </div>
            <p className={styles.last}>
              <a href="https://youtu.be/c_iBG0ZgmPo" target="_blank" rel="noreferrer">
                It’s Christmas
              </a>{' '}
              claymation video went viral with 7.5 million views, and the latest release in the
              series The Beauty of Horror 5: Haunt of Fame is a twisted homage to some of pop
              culture’s most beloved celebrities. It was released in August 2021 by IDW/Penguin
              Random House and took Amazon’s #1 Hot New Release spot in multiple categories. Alan
              Robert studied cartooning and illustration under the guidance of legendary Thor artist
              Walt Simonson at The School of Visual Arts in New York City, where he attended on
              scholarship.
            </p>
            <img src={imageAlan3} className="mobile_block" alt="Alan Robert 5" />
          </div>
          <button className="btn" onClick={() => setShowExtraBio((prevState) => !prevState)}>
            {showExtraBio ? `Close read more` : `Read more`}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Video;
