import React from 'react';
import styles from './Transmedia.module.css';
import imageTransmedia from '../../../assets/monsters/images/image_transmedia.png';

const Transmedia = () => {
  return (
    <section id={styles.transmedia} className="bg">
      <div className="inner">
        <div className="grid">
          <div className="col_7">
            <h2>
              <span>be part of the</span>
              <strong className="red">Monster universe</strong>
            </h2>
            <ul>
              <li>
                <span>
                  DAO Graphic Novel - The community will have a special role about the story and its
                  characters!
                </span>
              </li>
              <li>
                <span>Monster Chompers in real life! Toys and other physical collectibles.</span>
              </li>
              <li>
                <span>An upcoming animated series!</span>
              </li>
            </ul>
          </div>
          <div id={styles.transmedia_img} className="col_5">
            <img src={imageTransmedia} alt="Monster Chompers Transmedia" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transmedia;
