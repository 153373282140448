import './Checkbox.css';

const Checkbox = ({
  onClick,
  type = 'checkbox',
  styled = 'input-light fs-14',
  name = '',
  text = '',
}) => {
  return (
    <div className="mt-3">
      <div className="checkbox d-flex">
        <input onClick={onClick} type={type} className={styled} id={name} name={name} />
        <label className="checkbox-label" for={name}>
          {text}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
