import Routes from './routes';
import MarketProvider from './context/MarketContext';
import OnboardProvider from './context/OnboardContext';

function App() {
  return (
    <OnboardProvider>
      <MarketProvider>
        <Routes />
      </MarketProvider>
    </OnboardProvider>
  );
}

export default App;
