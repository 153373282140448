import styles from './Modal.module.css';
import cross from '../../../assets/monsters/images/cross.png';

const Modal = ({ showModal, children, styled, onClick }) => {
  return (
    <>
      {showModal && (
        <div id={styles.customModal} className={`${styled}`}>
          <div className="my-4" id={styles.modalWrapper}>
            <div className="close-modal text-right" onClick={onClick}>
              <img src={cross} alt={`Modal`}></img>
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
