import Button from '../../components/Monsters/Onboarding/Button';
import { Link } from 'react-router-dom';

const WhichWallet = () => {
  return (
    <div className="container-fluid">
      <h1>Create your wallet</h1>
      <p>Nemo enim ipsam voluptatem quia.</p>
      <Button>
        <Link to={{ pathname: 'https://metamask.io/' }} target="_blank">
          Create a Fortmatic wallet
        </Link>
      </Button>
    </div>
  );
};

export default WhichWallet;
