import Image from '../../assets/monsters/images/drop_placeholder.png';
import Tabs from '../../components/Monsters/Onboarding/Tabs';
import BasicContentBlock from '../../components/Monsters/Onboarding/BasicContentBlock';
import FortmaticIcon from '../../assets/monsters/images/formatic_icon.png';
import MetamaskIcon from '../../assets/monsters/images/metamask_icon.png';
import InfoIcon from '../../assets/monsters/images/info_icon.svg';
import WalletSetup from './WalletSetup';
import imagePoster from '../../assets/monsters/images/poster.jpg';
import VideoPlaceholder from '../../assets/monsters/video/video.mp4';
import InfoMessage from '../../components/Monsters/Onboarding/InfoMessage';
import Questionnaire from '../../components/Monsters/Onboarding/Questionnaire';
import HeaderBack from '../../components/Monsters/Header/HeaderBack';

const HowWalletsWork = () => {
  const walletsInfo = {
    fortmatic: {
      mobile: [
        {
          id: 1,
          title: 'Formatic Custodial wallet',
          time: '2 min read',
          img: Image,
          text: [
            'The only way to purchase an NFT is with Ethereum (ETH) using a MetaMask wallet. It’s important to set up your  wallet before the drop to ensure a quick purchase when the drop goes live.',
            'Cursus morbi at morbi sed cras turpis sapien nullam. Pharetra vel mollis suscipit ultricies.',
          ],
          content: (
            <>
              <h1 className="fs-24 mt-4">Create a Fortmatic wallet </h1>
              <div>
                <h5 className="fs-16 mt-1 d-inline-block">Step 1</h5>
                <span className="fs-7 d-inline-block ml-1">2 min read</span>
              </div>
              <p className="fs-7 mt-3">
                The only way to purchase an NFT is with Ethereum (ETH) using a MetaMask wallet. It’s
                important to set up your wallet before the drop to ensure a quick purchase when the
                drop goes live.
              </p>
              <p className="fs-7 mt-3">
                Get a free MetaMask wallet here:
                <a href="https://metamask.io" target="_blank" rel="noreferrer" className="d-block">
                  metamask.io
                </a>
              </p>
              <img className="mt-3" alt={`metamask`} src={Image} />
              <p className="fs-7 mt-3">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
              <InfoMessage
                img={InfoIcon}
                title="Lorem Ipsum"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et vel quis purus, eget amet justo orci orci."
              />
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
              <Questionnaire question="Was this article helpful?" />
            </>
          ),
        },
        {
          id: 2,
          title: 'Add enough funds to your Fortmatic wallet',
          time: '1 min read',
          img: Image,
          text: [
            'You can easily buy Ethereum with a debit card or Apple Pay directly within MetaMask by clicking "Add Funds".',
            'Alternatively, you can buy ETH from centralized exchanges like Coinbase or Binance. Then just send your ETH to your MetaMask wallet.',
          ],
          content: (
            <>
              <h1>Step 2</h1>
              <p>Test paragraph</p>
              <img src={Image} alt={`metamask`} />
              <a href="https://metamask.io" target="_blank" rel="noreferrer">
                Link test
              </a>
            </>
          ),
        },
        {
          id: 3,
          title: 'Sign in with your Fortmatic Wallet to website',
          time: '3 min read',
          img: Image,
          text: [
            'Cursus morbi at morbi sed cras turpis sapien nullam. Pharetra vel mollis suscipit ultricies.',
            'Cursus morbi at morbi sed cras turpis sapien nullam. Pharetra vel mollis suscipit ultricies.',
          ],
          content: <></>,
        },
        {
          id: 4,
          title: 'Select your favorite NFT',
          time: '4 min read',
          img: Image,
          text: [
            'Pharetra vel mollis suscipit ultricies. lorem ipsum',
            'Cursus morbi at morbi sed cras turpis sapien nullam. Pharetra vel mollis suscipit ultricies.',
          ],
          content: <></>,
        },
        {
          id: 5,
          title: 'Done! You have your first NFT',
          time: '1 min read',
          img: Image,
          text: [
            'Pharetra vel mollis suscipit ultricies. lorem ipsum',
            'Cursus morbi at morbi sed cras turpis sapien nullam. Pharetra vel mollis suscipit ultricies.',
          ],
          content: <></>,
        },
      ],
      desktop: [],
    },
    metamask: {
      mobile: [],
      desktop: [],
    },
  };

  const data = [
    {
      id: 1,
      name: 'Fortmatic',
      imgIcon: FortmaticIcon,
      content: () => (
        <div>
          <WalletSetup info={walletsInfo.fortmatic}></WalletSetup>
        </div>
      ),
      state: 'active',
    },
    {
      id: 2,
      name: 'Metamask',
      imgIcon: MetamaskIcon,
      content: () => <div>MetamMask content</div>,
      state: 'deactive',
    },
  ];

  return (
    <div className="pt-5">
      <HeaderBack />
      <div className="container-fluid mt-3 px-2 pb-5">
        <BasicContentBlock
          title="How does each wallet work?"
          subtitle="Choose a wallet and learn how to use it:"
        />
        <Tabs data={data}></Tabs>
      </div>

      <div className="container-fluid px-2 py-5 text-center">
        <div className="w-80 ml-auto mr-auto">
          <BasicContentBlock
            title="Still need help?"
            text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium."
            video={VideoPlaceholder}
            poster={imagePoster}
          />
        </div>
      </div>
    </div>
  );
};

export default HowWalletsWork;
