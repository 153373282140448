import onboard from 'bnc-onboard';
import { FORTMATIC_KEY, DAPP_ID_KEY, NETWORK_ID } from '../../config';

export function initOnboard(subscriptions) {
  return onboard({
    dappId: DAPP_ID_KEY,
    hideBranding: true,
    networkId: parseInt(NETWORK_ID),
    darkMode: true,
    subscriptions,
    walletSelect: {
      heading: 'Connect your Wallet',
      description: 'Monster Chompers',
      explanation: `
        Crypto wallets store your private keys, keeping your crypto safe and accessible. They also allow you to send, receive, and spend cryptocurrencies like Bitcoin and Ethereum.<br /><br />
        If you're a begginer we recommend <a href="https://blog.coincodecap.com/fortmatic-wallet-review#What_is_Fortmatic" target="_blank" rel="noreferrer noopener">Fortmatic</a>.<br />
        If you're experienced we recommend <a href="https://metamask.io/" target="_blank" rel="noreferrer noopener">MetaMask</a>.`,
      wallets: [
        {
          walletName: 'detectedwallet',
          display: {
            desktop: false,
            mobile: false,
          },
        },
        {
          walletName: 'metamask',
          preferred: true,
        },
        {
          walletName: 'fortmatic',
          apiKey: FORTMATIC_KEY,
          preferred: true,
        },
      ],
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
      { checkName: 'balance', minimumBalance: '100000' },
    ],
  });
}
