import './Questionnaire.css';
import Button from './Button';

function Questionnaire(props) {
  return (
    <div className="questionnaire my-4 py-4">
      <h1 className="fs-16 fw-bold">{props.question}</h1>
      <div className="answer-questionnaire mt-3">
        <Button styled="btn-light fs-16 fw-bold text-center">Yes</Button>
        <Button styled="btn-light fs-16 fw-bold text-center">No</Button>
      </div>
    </div>
  );
}

export default Questionnaire;
