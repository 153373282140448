import React from 'react';
import styles from './Press.module.css';

import imagePress1 from '../../../assets/monsters/images/press_1.jpg';
import imagePress2 from '../../../assets/monsters/images/press_2.jpg';
import imagePress3 from '../../../assets/monsters/images/press_3.jpg';
import imagePress4 from '../../../assets/monsters/images/press_4.jpg';
import imagePress5 from '../../../assets/monsters/images/press_5.jpg';
import imagePress6 from '../../../assets/monsters/images/press_6.jpg';
import imagePress7 from '../../../assets/monsters/images/press_7.jpg';
import imagePress8 from '../../../assets/monsters/images/press_8.jpg';
import imagePress9 from '../../../assets/monsters/images/press_9.jpg';
import imagePress10 from '../../../assets/monsters/images/press_10.jpg';
import imagePress11 from '../../../assets/monsters/images/press_11.jpg';

import logoPress1 from '../../../assets/monsters/images/logopress_yahoo.png';
import logoPress2 from '../../../assets/monsters/images/logopress_investor.png';
import logoPress3 from '../../../assets/monsters/images/logopress_whale.png';
import logoPress4 from '../../../assets/monsters/images/logopress_brave.png';
import logoPress5 from '../../../assets/monsters/images/logopress_villain.png';
import logoPress6 from '../../../assets/monsters/images/logopress_injection.png';
import logoPress7 from '../../../assets/monsters/images/logopress_nftnyc.svg';
import logoPress8 from '../../../assets/monsters/images/logopress_authority.png';
import logoPress9 from '../../../assets/monsters/images/logopress_cryptoclub.png';

import Slider from 'react-slick';

const Press = () => {
  const settings = {
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <section id={styles.press}>
      <div className="inner">
        <h2>
          News & <strong className="red">press</strong>
        </h2>
        <p className={styles.txt}>Some scrumptious media highlights for Monster Chompers!</p>
        <div id={styles.press_carousel}>
          <Slider {...settings}>
            <article className="col_4">
              <a
                href="https://medium.com/authority-magazine/alan-robert-of-wasted-talent-entertainment-5-things-i-wish-someone-told-me-when-i-first-became-an-caf320585642"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress10} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Authority Magazine <img src={logoPress8} alt="Authority Magazine" />
                </h3>
                <p>
                  Alan Robert of Wasted Talent Entertainment: "5 things I wish someone told me when
                  I first became an artist"
                </p>
                <a
                  href="https://medium.com/authority-magazine/alan-robert-of-wasted-talent-entertainment-5-things-i-wish-someone-told-me-when-i-first-became-an-caf320585642"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://www.youtube.com/watch?v=Z-_ZmLhLcik"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress11} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Crypto Club <img src={logoPress9} alt="Crypto Club" />
                </h3>
                <p>
                  Franchise Business in the Metaverse?! Monster Chompers @ Crypto Club YouTube
                  channel
                </p>
                <a
                  href="https://www.youtube.com/watch?v=Z-_ZmLhLcik"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  View video
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://finance.yahoo.com/finance/news/monster-chompers-nfts-rockstar-alan-180400490.html"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress8} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Yahoo Finance <img src={logoPress1} alt="Yahoo Finance" />
                </h3>
                <p>
                  "Monster Chompers NFTs from rockstar Alan Robert revolutionizes arts and
                  entertainment by putting fans in control"
                </p>
                <a
                  href="https://finance.yahoo.com/finance/news/monster-chompers-nfts-rockstar-alan-180400490.html"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://newmoneyinvestor.com/2021/12/13/monster-chompers-nfts-from-rockstar-alan-robert-revolutionizes-arts-and-entertainment-by-putting-fans-in-control/"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress9} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  New Money Investor <img src={logoPress2} alt="New money investor" />
                </h3>
                <p>
                  "Beauty of Horror Artist Alan Robert makes his first drop, offering franchise
                  opportunities to collectors "
                </p>
                <a
                  href="https://newmoneyinvestor.com/2021/12/13/monster-chompers-nfts-from-rockstar-alan-robert-revolutionizes-arts-and-entertainment-by-putting-fans-in-control/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://waytoodigital.notion.site/WTD-x-clients-meet-IRL-NFT-NYC-2021-NFT-land-61ceb62d78da441aa8e1dd269343bb8f"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress7} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Chompers in nyc! <img src={logoPress7} alt="NFT NYC" />
                </h3>
                <p>
                  Our team was present at the most important NTF conference in the world with the
                  most prominent figures of the NFT space.
                </p>
                <a
                  href="https://waytoodigital.notion.site/WTD-x-clients-meet-IRL-NFT-NYC-2021-NFT-land-61ceb62d78da441aa8e1dd269343bb8f"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://newmoneyinvestor.com/2021/11/01/monster-chompers-nft-goes-live/"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress2} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  New Money Investor <img src={logoPress2} alt="New money investor" />
                </h3>
                <p>
                  "These collectible works of art are cool as hell! These Monster Chompers are 100%
                  original and look amazing!"
                </p>
                <a
                  href="https://newmoneyinvestor.com/2021/11/01/monster-chompers-nft-goes-live/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://medium.com/whale-community/alan-roberts-monster-chompers-f0330bdd51e9"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress3} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  $WHALE Community
                  <img src={logoPress3} alt="Yahoo Finance" />
                </h3>
                <p>
                  "With legendary metal roots and an unmistakable styling, best-selling author Alan
                  Robert is bringing his award-winning horror illustration to the NFT table for a
                  bite."
                </p>
                <a
                  href="https://medium.com/whale-community/alan-roberts-monster-chompers-f0330bdd51e9"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://bravewords.com/news/life-of-agony-s-alan-robert-to-launch-monster-chompers-nft-collection-this-halloween-video-trailer"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress4} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Brave Words <img src={logoPress4} alt="Yahoo Finance" />
                </h3>
                <p>
                  "Best-selling artist Alan Robert is dropping his very first NFT collection and has
                  partnered with top software agency Way Too Digital to develop the limited,
                  one-of-a-kind, digital collectibles."
                </p>
                <a
                  href="https://bravewords.com/news/life-of-agony-s-alan-robert-to-launch-monster-chompers-nft-collection-this-halloween-video-trailer"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://finance.yahoo.com/news/bestselling-beauty-horror-creator-alan-130000666.html"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress1} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Yahoo Finance <img src={logoPress1} alt="Yahoo Finance 2" />
                </h3>
                <p>
                  "Monster Chompers takes horror and comedy, and throws them in the blender together
                  to produce something hilarious, twisted and completely unique"
                </p>
                <a
                  href="https://finance.yahoo.com/news/bestselling-beauty-horror-creator-alan-130000666.html"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://villainmedia.com/5-reasons-were-excited-for-monster-chompers/"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress5} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Villain Media
                  <img src={logoPress5} alt="Yahoo Finance" />
                </h3>
                <p>
                  "Each non-fungible token has a varying set of character traits that determine its
                  rarity. First off, there are multiple monster species available, such as vampires,
                  werewolves, mummies, zombies, and more."
                </p>
                <a
                  href="https://villainmedia.com/5-reasons-were-excited-for-monster-chompers/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>

            <article className="col_4">
              <a
                href="https://metalinjection.net/metal-merch/life-of-agonys-alan-robert-announces-monster-chompers-nft-collection"
                target="_blank"
                rel="noreferrer"
                className={styles.img_wrapper}
              >
                <img src={imagePress6} alt="Article pic" />
              </a>
              <div className={styles.header}>
                <h3>
                  Metal Injection
                  <img src={logoPress6} alt="Yahoo Finance" />
                </h3>
                <p>
                  "All elements of Monster Chompers were hand drawn by Robert and randomly generated
                  using algorithms to produce the 10,000 monsters. The NFTs are then minted and live
                  on the Ethereum blockchain."
                </p>
                <a
                  href="https://metalinjection.net/metal-merch/life-of-agonys-alan-robert-announces-monster-chompers-nft-collection"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btn_press}
                >
                  Read article
                </a>
              </div>
            </article>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Press;
