import { Switch, Route, Redirect } from 'react-router-dom';
import Monsters from '../pages/Monsters';

import WhichWallet from '../pages/user-onboarding/WhichWallet';
import OnBoard from '../pages/user-onboarding/OnBoard';
import WalletFaq from '../pages/user-onboarding/WalletFaq';
import Register from '../pages/user-onboarding/Register';
import HowItWorks from '../pages/user-onboarding/HowItWorks';
import HowWalletsWork from '../pages/user-onboarding/HowWalletsWork';
import WelcomePage from '../pages/user-onboarding/WelcomePage';
import Profile from '../pages/UserProfile';
import Token from '../pages/Token';

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Monsters} />
        <Route exact path="/profile/:userAddress" component={Profile} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/how-it-works">
          <Route exact path="/how-it-works" component={HowItWorks} />
          <Route exact path="/how-it-works/welcome" component={WelcomePage} />
          <Route path="/how-it-works/wallets-faq">
            <Route exact path="/how-it-works/wallets-faq" component={WalletFaq} />
            <Route
              exact
              path="/how-it-works/wallets-faq/what-is-a-wallet"
              component={WhichWallet}
            />
            <Route
              exact
              path="/how-it-works/wallets-faq/which-can-you-create"
              component={Register}
            />
            <Route
              exact
              path="/how-it-works/wallets-faq/how-wallets-work"
              component={HowWalletsWork}
            />
            <Route exact path="/how-it-works/wallets-faq/how-to-sign" component={OnBoard} />
          </Route>
        </Route>
        <Route exact path="/token/:tokenId" component={Token} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

export default Routes;
