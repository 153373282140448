import Accordion from '../../components/Monsters/Onboarding/Accordion';
import InfoStepper from '../../components/Monsters/Onboarding/InfoStepper';
import { MdOutlineDesktopWindows } from 'react-icons/md';
import { BiMobile } from 'react-icons/bi';
import styles from './WalletSetup.module.css';

const WalletSetup = ({ title, subtitle, text, info }) => {
  return (
    <div className={` ${styles.accordionWrapper} container-fluid`}>
      {title && <h1 className="fs-24 mt-2">{title}</h1>}
      {subtitle && <h5 className="fs-14 mt-1">{subtitle}</h5>}
      {text && <p className="fs-14 mt-1 mb-2">{text}</p>}
      <Accordion
        children={<BiMobile fontSize="27px" />}
        title="Mobile"
        content={<InfoStepper steps={info.mobile}></InfoStepper>}
      ></Accordion>
      <Accordion
        children={<MdOutlineDesktopWindows fontSize="27px" />}
        title="Desktop"
        content={<InfoStepper steps={info.desktop}></InfoStepper>}
      ></Accordion>
    </div>
  );
};

export default WalletSetup;
