import React from 'react';
import styles from './MintContainer.module.css';
import imageMonsterMint from '../../../assets/monsters/images/whitelist-launch-skeleton.png';
import monsterMint from '../../../assets/monsters/images/monster_mint.png';
import Marquee from '../Marquee/Marquee';
import Whitelist from '../Whitelist/Whitelist';
import Mint from '../Mint/Mint';
import { MINT_ENABLED } from '../../../config';

const MintContainer = () => {
  return (
    <section id={styles.mint}>
      <Marquee />
      <div id={styles.mint_area} className="inner">
        <div className="grid">
          <div className="col_7">
            {MINT_ENABLED === 'true' ? (
              <>
                <h1>
                  Join the club!
                  <span>
                    Mint your <strong className="red">Chomper</strong>
                  </span>
                </h1>
                <img src={monsterMint} alt="Monster illustration" width="420" />
              </>
            ) : (
              <>
                <h1>
                  <span>
                    Enter the{' '}
                    <strong className="red">
                      Pre<i>-</i>sale
                    </strong>
                  </span>
                  Join the club!
                </h1>
                <img src={imageMonsterMint} alt="Monster illustration" width="420" />
              </>
            )}
          </div>
          <div className="col_5">{MINT_ENABLED === 'true' ? <Mint /> : <Whitelist />}</div>
        </div>
      </div>
    </section>
  );
};

export default MintContainer;
