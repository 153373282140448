import { useState, useEffect } from 'react';
import LargeBtn from '../../components/Monsters/Onboarding/LargeBtn';
import { RiWallet3Line } from 'react-icons/ri';
import { HiOutlinePuzzle } from 'react-icons/hi';
import { useOnboardContext, useAddress } from '../../context/OnboardContext';
import HeaderBack from '../../components/Monsters/Header/HeaderBack';
import styles from './OnBoard.module.css';

const OnBoard = () => {
  const [setActive] = useState('');

  const { linkWallet, resetWallet } = useOnboardContext();
  const address = useAddress();

  //   function closeMenu() {
  //     console.log('Closed');
  //     setActiveState(setActive === '' ? 'd-none' : '');
  //   }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={`container-fluid ${styles.helpContainerWrapper} pt-5 ${setActive}`}>
      <HeaderBack />
      <h1 className="fs-60 fw-bold mt-3 font-carnival">Help center</h1>
      <p className="mt-1 mb-2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris
      </p>
      <div className="d-block d-md-flex">
        <LargeBtn
          icon={<RiWallet3Line color="white" fontSize="32px" />}
          typeButton={true}
          onClick={!address ? linkWallet : resetWallet}
          title={`Sign in with your wallet ${address ? `(Conected!)` : ``}`}
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa."
          ctaText="Connect wallet"
        />

        <LargeBtn
          icon={<HiOutlinePuzzle color="white" fontSize="32px" />}
          path="/how-it-works/wallets-faq"
          title="Learn more about wallets"
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa."
          ctaText="Learn more"
        />
      </div>
    </div>
  );
};

export default OnBoard;
