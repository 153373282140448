import React, { useState, useEffect, useCallback } from 'react';
import HeaderBack from '../components/Monsters/Header/HeaderBack';
import TokenContainer from '../components/Monsters/TokenContainer/TokenContainer';
import { useParams } from 'react-router-dom';
import Web3 from 'web3';
import axios from 'axios';
import { factoryAddress, factoryAbi } from '../services/onboard/contract';
import { WEB3_PROVIDER } from '../config/index';
import iconLoading from '../assets/monsters/images/loading.svg';
import styles from './Token.module.css';

const ipfsBaseURl = `https://ipfs.io/ipfs/`;

const Token = () => {
  const { tokenId } = useParams();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);

  const getTokenData = useCallback(async () => {
    try {
      setLoading(true);
      const web3 = new Web3(WEB3_PROVIDER);
      const myContract = new web3.eth.Contract(factoryAbi, factoryAddress);
      const ipfsUri = await myContract.methods.tokenURI(tokenId).call();
      const metadata = await axios.get(`${ipfsBaseURl}${ipfsUri.substring(6)}`);
      setToken({
        ...metadata.data,
        tokenId,
        image: `${ipfsBaseURl}${metadata.data.image.substring(6)}`,
      });
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [tokenId]);

  useEffect(() => {
    getTokenData();
  }, [tokenId, getTokenData]);

  return (
    <div>
      <HeaderBack />
      {loading ? (
        <div className={styles.loading_wrapper}>
          <img src={iconLoading} alt="Loading" />
        </div>
      ) : !error ? (
        <TokenContainer token={token} />
      ) : (
        <div className={styles.loading_wrapper}>
          <p>This NFT has not been minted yet</p>
        </div>
      )}
    </div>
  );
};

export default Token;
