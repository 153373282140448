import React from 'react'
import styles from './Subscribe.module.css'
import SubscribeForm from '../SubscribeForm/SubscribeForm'

const Subscribe = () => {
    return (
        <section id={styles.subscribe}>
            <div className="inner">
                <div className={styles.header}>
                    <h2>Feed me updates!</h2>
                    <p>Sink your teeth into all the tasty Monster Chompers news and announcements.</p>
                </div>
                <SubscribeForm />
            </div>
        </section>
    )
}

export default Subscribe