import React from 'react';
import styles from './Footer.module.css';
import imageIcontwitter from '../../../assets/monsters/images/icon_twitter.svg';
import imageIcondiscord from '../../../assets/monsters/images/icon_discord.svg';
import imageIconinstagram from '../../../assets/monsters/images/icon_instagram.svg';
import imageIconfacebook from '../../../assets/monsters/images/icon_facebook.svg';
import imageIconOpensea from '../../../assets/monsters/images/icon_opensea.svg';
import imageLogonftcalendar from '../../../assets/monsters/images/nft_calendar.png';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import WTDSign from '../WTDSign/WTDSign';

const Footer = () => {
  return (
    <footer id={styles.footer}>
      <div className="inner">
        <nav>
          <ul>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="mint"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
              >
                Mint
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="menu"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
              >
                Our Menu
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="roadmap"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Roadmap
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="team"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="anchor_link"
                to="faq"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
              >
                FAQ
              </Link>
            </li>
            <li>
              <RouterLink to={'/profile'}>Profile</RouterLink>
            </li>
          </ul>
        </nav>
        <div id={styles.social_footer}>
          <a href="https://instagram.com/monsterchompers" target="_blank" rel="noreferrer">
            <img src={imageIconinstagram} alt="Instagram" />
          </a>
          <a href="https://facebook.com/MonsterChompers" target="_blank" rel="noreferrer">
            <img src={imageIconfacebook} alt="Facebook" />
          </a>
          <a href="https://twitter.com/monsterchomp3rs" target="_blank" rel="noreferrer">
            <img src={imageIcontwitter} alt="Twitter" target="_blank" rel="noreferrer" />
          </a>
          <a href="https://discord.com/invite/8cZDDNWRKa" target="_blank" rel="noreferrer">
            <img src={imageIcondiscord} alt="Discord" />
          </a>
          <a href="https://opensea.io/collection/monsterchompers" target="_blank" rel="noreferrer">
            <img src={imageIconOpensea} alt="OpenSea" />
          </a>
        </div>
        <p id={styles.copyright}>
          Copyright© Monster Chompers 2021. <span>All rights reserved</span>
        </p>
      </div>
      <div id={styles.sponsors}>
        <div className="inner">
          <div>
            <div>
              <WTDSign />
            </div>
            <div>
              <a
                href="https://nftcalendar.io/event/monster-chompers/"
                target="_blank"
                rel="noreferrer"
              >
                <img id={styles.nft_calendar_logo} src={imageLogonftcalendar} alt="NFT Calendar" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
