import React from 'react'
import logoWTD from '../../../assets/monsters/images/wtd_logo.svg'
import styles from './WTDSign.module.css'

const WTDSign = () => {
    return (
        <div id={styles.sign}>
            
               <a target="_blank" rel="noreferrer" href="https://www.waytoodigital.com/?projectid=monsterchompers.com"><img src={logoWTD} alt="Way Too Digital" /></a>
            
        </div>
    )
}

export default WTDSign
