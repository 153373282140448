import './InfoMessage.css';

function Accordion({ title, img, text }) {
  return (
    <div className="alert my-2 py-2">
      <div className="title-wrapper">
        <img className="d-inline-block" alt={title} src={img}></img>
        <h1 className="d-inline-block fs-16 ml-1">{title}</h1>
      </div>
      <p className="ml-1">{text}</p>
    </div>
  );
}

export default Accordion;
