import React from 'react';
import styles from './TokenContainer.module.css';
import { CONTRACT_ADDRESS } from '../../../config/index';

const TokenContainer = ({ token: { image, name, attributes, tokenId } }) => {
  return (
    <div className={styles.token_wrapper}>
      <div className={styles.grid}>
        <div className={styles.col_6}>
          <div className={styles.img_wrapper}>
            <img src={image} alt={name} />
          </div>
        </div>
        <div className={styles.col_6}>
          <div className={styles.data_wrapper}>
            <h1>{name}</h1>
            <div className={styles.heading_properties}>
              <h2>Properties</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId}`}
              >
                View on OpenSea
              </a>
            </div>
            <ul>
              {attributes.map(({ trait_type, value }) => (
                <li key={value}>
                  <h3>{trait_type}</h3>
                  <p>{value}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenContainer;
