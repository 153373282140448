import styles from './ProfileBanner.module.css';
import WalletButton from '../WalletButton/WalletButton';

const ProfileBanner = ({ wallet }) => {
  return (
    <div>
      <div className={styles.profileInfoWrapper}>{<WalletButton showProfile={false} />}</div>
    </div>
  );
};

export default ProfileBanner;
