import React from 'react';
import styles from './Hero.module.css';
import imageLogo from '../../../assets/monsters/images/logo_mc.png';
import imageLogomobile from '../../../assets/monsters/images/logo_mc_mobile.png';
import imageIcontwitter from '../../../assets/monsters/images/icon_twitter.svg';
import imageIcondiscord from '../../../assets/monsters/images/icon_discord.svg';
import imageIconinstagram from '../../../assets/monsters/images/icon_instagram.svg';
import imageIconfacebook from '../../../assets/monsters/images/icon_facebook.svg';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <section id={styles.hero}>
      <div className={styles.data}>
        <img className={styles.logo} src={imageLogo} alt="Monster Chompers, By Alan Robert" />
        <img
          className={styles.logo_mobile}
          src={imageLogomobile}
          alt="Monster Chompers, By Alan Robert"
        />
        <Link className="btn" to="mint" spy={true} smooth={true} duration={500} offset={-90}>
          Mint now!
        </Link>

        <div id={styles.social_mobile}>
          <a href="https://instagram.com/monsterchompers" target="_blank" rel="noreferrer">
            <img src={imageIconinstagram} alt="Instagram" />
          </a>
          <a href="https://facebook.com/MonsterChompers" target="_blank" rel="noreferrer">
            <img src={imageIconfacebook} alt="Facebook" />
          </a>
          <a href="https://twitter.com/monsterchomp3rs" target="_blank" rel="noreferrer">
            <img src={imageIcontwitter} alt="Twitter" />
          </a>
          <a href="https://discord.com/invite/8cZDDNWRKa" target="_blank" rel="noreferrer">
            <img src={imageIcondiscord} alt="Discord" />
          </a>
        </div>
      </div>
    </section>
  );
};
export default Hero;
