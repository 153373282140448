import { RiWallet3Line } from 'react-icons/ri';
import { BiGlobe } from 'react-icons/bi';
import { MdOutlineCreditCard } from 'react-icons/md';
import HeaderBack from '../../components/Monsters/Header/HeaderBack';
import LargeBtn from '../../components/Monsters/Onboarding/LargeBtn';
import { Element } from 'react-scroll';

const HowItWorks = () => {
  return (
    <div className="container-fluid mb-4 pt-5 px-2">
      <Element name="header" className="element">
        <HeaderBack />
      </Element>
      <h1 className="fs-60 fw-bold mt-3 mb-1 font-carnival">How it works</h1>
      <p className="mb-5">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.
      </p>
      <div className="d-md-flex">
        <LargeBtn
          icon={<RiWallet3Line color="white" fontSize="32px" />}
          path="/how-it-works/wallets-faq"
          title="Wallets"
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa."
          ctaText="Read more"
        />
        <LargeBtn
          icon={<BiGlobe color="white" fontSize="32px" />}
          path="/how-it-works/wallets-faq"
          title="Blockchain"
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium"
          ctaText="Read more"
        />
        <LargeBtn
          icon={<MdOutlineCreditCard color="white" fontSize="32px" />}
          path="/how-it-works/wallets-faq"
          title="Payment"
          description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa."
          ctaText="Read more"
        />
      </div>
    </div>
  );
};

export default HowItWorks;
