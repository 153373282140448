import styles from './DetailPill.module.css';

function DetailPill(props) {
  const content = props.details.map((detail, index) => (
    <div className={styles.pillInfo} key={index}>
      <h2>{detail.trait_type}</h2>
      <p>{detail.value}</p>
    </div>
  ));

  return <div className={styles.pillsWrapper}>{content}</div>;
}

export default DetailPill;
