const networkName = (id) => {
  switch (Number(id)) {
    case 1:
      return 'main';
    case 3:
      return 'ropsten';
    case 4:
      return 'rinkeby';
    case 5:
      return 'goerli';
    case 42:
      return 'kovan';
    case 100:
      return 'xdai';
    case 'localhost':
      return 'localhost';
    default:
      return 'local';
  }
};

const truncateWeb3Address = (address) => {
  const truncatedAddress = `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length,
  )}`;
  return truncatedAddress;
};

const metamaskErrors = {
  '-32700': {
    standard: 'JSON RPC 2.0',
    message:
      'Invalid JSON was received by the server.' +
      'An error occurred on the server while parsing the JSON text.',
  },
  '-32600': {
    standard: 'JSON RPC 2.0',
    message: 'The JSON sent is not a valid Request object.',
  },
  '-32601': {
    standard: 'JSON RPC 2.0',
    message: 'The method does not exist / is not available.',
  },
  '-32602': {
    standard: 'JSON RPC 2.0',
    message: 'Invalid method parameter(s).',
  },
  '-32603': {
    standard: 'JSON RPC 2.0',
    message: 'Internal JSON-RPC error.',
  },
  '-32000': {
    standard: 'EIP-1474',
    message: 'Invalid input.',
  },
  '-32001': {
    standard: 'EIP-1474',
    message: 'Resource not found.',
  },
  '-32002': {
    standard: 'EIP-1474',
    message: 'Resource unavailable.',
  },
  '-32003': {
    standard: 'EIP-1474',
    message: 'Transaction rejected.',
  },
  '-32004': {
    standard: 'EIP-1474',
    message: 'Method not supported.',
  },
  '-32005': {
    standard: 'EIP-1474',
    message: 'Request limit exceeded.',
  },
  4001: {
    standard: 'EIP-1193',
    message: 'User rejected the request.',
  },
  4100: {
    standard: 'EIP-1193',
    message: 'The requested account and/or method has not been authorized by the user.',
  },
  4200: {
    standard: 'EIP-1193',
    message: 'The requested method is not supported by this Ethereum provider.',
  },
  4900: {
    standard: 'EIP-1193',
    message: 'The provider is disconnected from all chains.',
  },
  4901: {
    standard: 'EIP-1193',
    message: 'The provider is disconnected from the specified chain.',
  },
};

export { networkName, truncateWeb3Address, metamaskErrors };
