import React, { useState } from 'react';
import styles from './Faq.module.css';
import iconArrowdown from '../../../assets/monsters/images/arrow_open.svg';
import iconArrowUp from '../../../assets/monsters/images/arrow_up.svg';
import { Link } from 'react-router-dom';

const FaqItem = ({ question, answer, index, faqOpen, setIsOpen }) => {
  return (
    <div className={styles.faq}>
      <button onClick={() => setIsOpen(index)} className={`${styles.faq_title}`}>
        <p>{question}</p>
        <img src={faqOpen === index ? iconArrowUp : iconArrowdown} alt="Open" />
      </button>
      <p className={`${styles.answer} ${faqOpen === index ? styles.active : ''}`}>{answer}</p>
    </div>
  );
};

const Faq = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const handleIsOpen = (index) => (faqOpen === index ? setFaqOpen(null) : setFaqOpen(index));

  return (
    <section id={styles.faq}>
      <div id="monster_wall"></div>
      <div className={styles.inner}>
        <div className="inner">
          <h2>Faq</h2>

          <FaqItem
            question={`When is launch?`}
            answer={`Pre-sale (Whitelist only): December 6th | Public sale: December 7th.`}
            index={1}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(1)}
          />
          <FaqItem
            question={`What is the total supply?`}
            answer={`10.000 unique Monster Chompers will be minted.`}
            index={2}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(2)}
          />
          <FaqItem
            question={`Will there be a presale?`}
            answer={`Yes, we have 2500 places reserved for the presale. There are 2 ways to get on the White List. The first 1000 to send an email through the form we have on our website will receive a link to a Whitelist Enrollment form. The additional 1,500 seats are reserved for the most active members of our community on Discord.`}
            index={3}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(3)}
          />
          <FaqItem
            question={`How can I buy a Monster Chomper?`}
            answer={`You have to connect a Wallet like MetaMask and have enough ETH to cover the cost of mint + gas (transaction fee of the Ethereum Blockchain)`}
            index={4}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(4)}
          />
          <FaqItem
            question={`How much will it cost to mint a Monster Chomper?`}
            answer={`0.05 ETH + GAS per Chomper.`}
            index={5}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(5)}
          />
          <FaqItem
            question={`Will my Chompers NFT be revealed immediately after purchase?`}
            answer={`Yes, the reveal of your NFT is right after the purchase.`}
            index={6}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(6)}
          />
          <FaqItem
            question={`How many Chompers can I mint in the same transaction?`}
            answer={`You can mint up to a maximum of 20 Chompers in the same transaction, without limit of transactions per Wallet.`}
            index={7}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(7)}
          />
          <FaqItem
            question={`Where can I check my purchased NFTs?`}
            answer={
              <span>
                You can check them in your{' '}
                <Link style={{ color: '#b70887' }} to={`/profile`}>
                  profile page
                </Link>
                .
              </span>
            }
            index={8}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(8)}
          />
          <FaqItem
            question={`How do I import my NFTs into MetaMask?`}
            answer={
              <span>
                You will need the project's contract address&nbsp;
                <code style={{ opacity: 0.8 }}>0xa647f9a598e10f94fe5a6d6bc83a02cbfbbc01ca</code> and
                the token ID of your NFT. You can get that ID listed in the name of your Chomper,
                check{' '}
                <Link style={{ color: '#b70887' }} to={`/profile`}>
                  your profile
                </Link>{' '}
                to see your purchased NFTs. Then follow the instructions listed in the{' '}
                <a
                  style={{ color: '#b70887' }}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://metamask.zendesk.com/hc/en-us/articles/360058238591-NFT-tokens-in-your-MetaMask-wallet`}
                >
                  MetaMask documentation
                </a>
                .
              </span>
            }
            index={9}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(9)}
          />
          <FaqItem
            question={`I am seeing a "Join the Pre-Sale" message when I try to mint on MetaMask Mobile. What can I do?`}
            answer={
              <span>
                If you're facing issues where you're seeing a <em>"Join the Pre-Sale"</em> message
                when connecting your wallet, this is most likely due to MetaMask Mobile having a
                persistent cache. In order to sort this out, do the following:
                <ol>
                  <li>Head over to the MetaMask Mobile app browser (Hamburger menu ➡️ Browser)</li>
                  <li>Head over to the Monster Chompers' website if you're not in it already</li>
                  <li>
                    Tap on the three dots at the bottom right corner to bring up the browser menu
                  </li>
                  <li>Tap on "Reload"</li>
                  <li>Try minting again</li>
                </ol>
                Manually tapping on reload triggers a cache refresh in MetaMask thus allowing you to
                mint.
              </span>
            }
            index={10}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(10)}
          />
          <FaqItem
            question={`Gas fees are incredibly high, what's happening?`}
            answer={
              <span>
                If you're seeing incredibly high gas fees it could be that the Ethereum Blockchain
                is having high gas costs because of a high demand. It is also a known issue that
                MetaMask goes crazy if you don't have enough funds to mint a Chomper + pay for the
                gas fees, rendering a crazy amount of gas fees. Make sure to have at least 0.55 or
                0.6 ETH to mint 1 Chomper.
              </span>
            }
            index={11}
            faqOpen={faqOpen}
            setIsOpen={() => handleIsOpen(11)}
          />
        </div>
      </div>
    </section>
  );
};

export default Faq;
