import { useState } from 'react';
import Button from './Button';
import Modal from './Modal';
import styles from './InfoStepper.module.css';

const Steps = ({ steps, setShowHeader }) => {
  const [showModal, setShowModal] = useState(false),
    [modalIndex, setModalIndex] = useState(null);

  const toggleModal = (index) => {
    /** Why is the modal not working? Check out later**/
    setModalIndex(index);
    setShowModal(!showModal);
  };

  return (
    <div>
      {steps.map(({ id, img, title, text, time }, index) => {
        return (
          <div className={`${styles.stepItem} d-flex`} key={index}>
            <div className={`${styles.stepperLine} w-15`}>
              <div
                className={`mt-5 ${styles.stepperNumber} ml-auto mr-auto text-center fw-bold fs-16`}
              >
                {id}
              </div>
            </div>
            <div className={`w-md-85 w-100 d-flex ml-1 ${styles.accordionWrapper}`}>
              <div className="w-md-65 d-inline-block">
                <h1 className="mt-5 text-left">{title}</h1>
                <h5>{time}</h5>
                <div className={`${styles.stepImage}`}>
                  <img src={img} alt={title}></img>
                </div>
                <p className="fs-16 my-1">{text[0]}</p>
                <Button
                  styled="btn-light text-center my-1"
                  onClick={() => {
                    toggleModal(index);
                  }}
                >
                  Read more
                </Button>
              </div>
              <div className="w-md-35 w-100 d-none d-md-block">
                <div className={`${styles.stepImage}`}>
                  <img src={img} alt={title}></img>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Modal showModal={showModal} setShowModal={setShowModal} onClick={() => setShowModal(false)}>
        {steps[modalIndex]?.content}
      </Modal>
    </div>
  );
};

export default Steps;
