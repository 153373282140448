import { Link } from 'react-router-dom';
import styles from './WalletFaq.module.css';
import HeaderBack from '../../components/Monsters/Header/HeaderBack';

const WalletFaq = () => {
  return (
    <div className={`container-fluid pt-5 ${styles.faqContainer}`}>
      <HeaderBack />
      <h1 className="mt-3 fs-60 font-carnival">Wallets</h1>
      <p className="mt-4 w-80">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris
      </p>
      <div className="wallet-faq-wrapper mt-3">
        {/* 
        <div className={styles.walletFaq}>
          <Link to="/how-it-works/wallets-faq/what-is-a-wallet">
            <h3 className="fs-16">What is a wallet?</h3>
          </Link>
        </div>
        */}
        <div className={styles.walletFaq}>
          <Link to="/how-it-works/wallets-faq/which-can-you-create">
            <h3 className="fs-16">Which wallet can you create?</h3>
          </Link>
        </div>
        <div className={styles.walletFaq}>
          <Link to="/how-it-works/wallets-faq/how-wallets-work">
            <h3 className="fs-16">How does each wallet work?</h3>
          </Link>
        </div>
        <div className={styles.walletFaq}>
          <Link to="/how-it-works/wallets-faq/how-to-sign">
            <h3 className="fs-16">How do I sign in with my wallet?</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WalletFaq;
