import Card from '../../components/Monsters/Onboarding/Card';
import Button from '../../components/Monsters/Onboarding/Button';
import { Link } from 'react-router-dom';
import InfoIcon from '../../assets/monsters/images/info_icon.svg';
import FormaticIcon from '../../assets/monsters/images/formatic_icon.png';
import MetamaskIcon from '../../assets/monsters/images/metamask_icon.png';
import styles from './Register.module.css';
import HeaderBack from '../../components/Monsters/Header/HeaderBack';

const Register = () => {
  return (
    <div className="w-md-80 w-100 ml-auto mr-auto pt-5 px-2 pb-2">
      <HeaderBack />
      <h1 className={`fs-60 font-carnival pt-1 mt-3 ${styles.mainTitle}`}>
        Which wallet can you create?
      </h1>
      <p className="mt-1 w-100 w-md-65 mb-4">
        Et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
        voluptas sit aspernatur aut odit aut fugit.
      </p>
      <div className="d-md-flex">
        <Card
          imageLogo={FormaticIcon}
          title="Fortmatic"
          text="Fortmatic is easier for new users, because you won't need to download anything, and you can login with an email and password."
          label="Beginner"
        >
          <Button styled="btn-light text-center my-2">
            <Link to={{ pathname: 'https://fortmatic.com/' }} target="_blank">
              Create a Fortmatic wallet
            </Link>
          </Button>
          <Link
            to={{ pathname: '/how-it-works/wallets-faq/how-wallets-work' }}
            className={styles.infoLink}
          >
            <img alt={`Formatic`} className="pr-1 d-inline-block" src={InfoIcon} />
            What is a Formatic wallet?
          </Link>
        </Card>
        <Card
          imageLogo={MetamaskIcon}
          title="Metamask"
          label="Advanced"
          text="You will have to download an extension for the browser and create one. Or an app if you want to browse in your    phone. Get a free MetaMask wallet by clicking on the button below."
        >
          <Button styled="btn-light text-center my-2">
            <Link to={{ pathname: 'https://metamask.io/' }} target="_blank">
              Create a Metamask wallet
            </Link>
          </Button>
          <Link
            to={{ pathname: '/how-it-works/wallets-faq/how-wallets-work' }}
            className={styles.infoLink}
          >
            <img alt={`Metamask`} className="pr-1 d-inline-block" src={InfoIcon} />
            How Metamask wallet works
          </Link>
        </Card>
      </div>
    </div>
  );
};

export default Register;
