import { useState, useRef, useEffect } from 'react';
import styles from './Accordion.module.css';

function Accordion({ title, children, content: contentProp }) {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    setHeight(!active ? '0px' : `${content.current.scrollHeight}px`);
  }, [active]);

  const content = useRef(null);

  const toggleAccordion = () => {
    setActive(!active);
  };

  return (
    <div className={styles.accordionSection}>
      <button
        className={`w-100 text-left ${styles.accordion} fw-bold ${active ? 'active' : ''}`}
        onClick={toggleAccordion}
      >
        {children && (
          <p className={styles.accordionTitle}>
            {children}
            {title}
          </p>
        )}
      </button>

      {content && (
        <div ref={content} style={{ maxHeight: `${height}` }} className={styles.accordionContent}>
          <div className={styles.accordionContent}> {contentProp}</div>
        </div>
      )}
    </div>
  );
}

export default Accordion;
