import React from 'react';
import styles from './Team.module.css';
import imageAlan from '../../../assets/monsters/images/team_alan.jpg';
import imageWTD from '../../../assets/monsters/images/team_wtd.jpg';
import imageIcontwitter from '../../../assets/monsters/images/icon_twitter.svg';
import imageIconinstagram from '../../../assets/monsters/images/icon_instagram.svg';
import imageIconfacebook from '../../../assets/monsters/images/icon_facebook.svg';
import imageIconwebsite from '../../../assets/monsters/images/icon_website.svg';
import imageIconlinkedin from '../../../assets/monsters/images/icon_linkedin.svg';
import imageBluecheck from '../../../assets/monsters/images/icon_bluecheck.svg';

import imageLogonft42 from '../../../assets/monsters/images/logo_nft42.png';
import imageLogonvayner from '../../../assets/monsters/images/logo_vayner.png';
import imageLogonusopen from '../../../assets/monsters/images/logo_usopen.png';
import imageLogonftland from '../../../assets/monsters/images/logo_nftland.png';
import imageLogoshyft from '../../../assets/monsters/images/logo_shyft.png';
import imageLogontwrk from '../../../assets/monsters/images/logo_ntwrk.png';
import imageLogogimmicks from '../../../assets/monsters/images/logo_gimmicks.png';
import imageLogoopenmeta from '../../../assets/monsters/images/logo_openmeta.png';

const Team = () => {
  return (
    <section id={styles.team}>
      <div className="inner">
        <h2>
          Meet the <span className="red">team</span>
        </h2>
        <p>Behind every great project there is a great team!</p>
        <div id={styles.members} className="grid">
          <div className="col_6">
            <img alt={`Alan`} src={imageAlan} />
            <div className={styles.data}>
              <span className="red">The Artist</span>
              <h3>
                Alan Robert
                <img src={imageBluecheck} alt="Blue Check" />
              </h3>
              <div className={styles.social}>
                <a href="https://www.alanrobert.com/" target="_blank" rel="noreferrer">
                  <img src={imageIconwebsite} alt="Website" />
                </a>
                <a href="https://www.instagram.com/alanrobert666/" target="_blank" rel="noreferrer">
                  <img src={imageIconinstagram} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/alanrobert" target="_blank" rel="noreferrer">
                  <img src={imageIconfacebook} alt="Facebook" />
                </a>
                <a href="https://twitter.com/arobert" target="_blank" rel="noreferrer">
                  <img src={imageIcontwitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>

          <div className="col_6">
            <img alt={`WayTooDigital`} src={imageWTD} />
            <div className={styles.data}>
              <span className="red">The Devs</span>
              <h3>Way too digital</h3>
              <div className={styles.social}>
                <a href="https://waytoodigital.com" target="_blank" rel="noreferrer">
                  <img src={imageIconwebsite} alt="Website" />
                </a>
                <a
                  href="https://www.linkedin.com/company/way-too-digital"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={imageIconlinkedin} alt="Linkedin" />
                </a>
                <a href="https://twitter.com/WayTooDigital" target="_blank" rel="noreferrer">
                  <img src={imageIcontwitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id={styles.logos_partners}>
          <h3>We have been working with</h3>
          <div className="grid">
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogonft42} alt="NFT 42" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogonvayner} alt="Vayner" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogonusopen} alt="Us Open" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogonftland} alt="NFT Land" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogoshyft} alt="Shyft" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogontwrk} alt="Ntwrk NFT" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogogimmicks} alt="The Gimmicks" />
            </div>
            <div className="col_3 col_tb_6 col_mb_6">
              <img src={imageLogoopenmeta} alt="Open Meta" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
