import React from 'react';
import styles from './Franchises.module.css';
import imageEarn from '../../../assets/monsters/images/imageEarn.png';
import imageStreetcart from '../../../assets/monsters/images/streetcart.png';
import imageFoodtruck from '../../../assets/monsters/images/foodtruck.png';
import imageMonsterbar from '../../../assets/monsters/images/monsterbar.png';
import imageBigrestaurant from '../../../assets/monsters/images/bigrestaurant.png';
import imageStreetcartmobile from '../../../assets/monsters/images/streetcart_mobile.jpg';
import imageFoodtruckmobile from '../../../assets/monsters/images/foodtruck_mobile.jpg';
import imageMonsterbarmobile from '../../../assets/monsters/images/monsterbar_mobile.jpg';
import imageBigrestaurantmobile from '../../../assets/monsters/images/bigrestaurant_mobile.jpg';
import imageBgfranchise from '../../../assets/monsters/images/bg_franchises.jpg';

const Franchises = () => {
  return (
    <section id={styles.franchises}>
      <div className="inner">
        <div className="grid">
          <div id={styles.img_franchises} className="col_5">
            <img src={imageEarn} alt="Monster illustration" />
          </div>
          <div id={styles.txt_earn} className="col_7">
            <h2>
              <strong>collect, earn and</strong>
              <span className="red">be rewarded</span>
            </h2>
            <p>
              By acquiring NFTs from the Monster Chompers All-Nite-Eatery collection, you will
              become official partners with Wallachia Gourmet Catering.
            </p>
            <p>
              Once you're a partner, <strong>you will start earning $WGC tokens</strong> that will
              help you open your own franchise! Franchise owners receive exclusive perks,{' '}
              <strong>including royalties straight to your wallet</strong> from NFT sales sold on
              our built-in Secondary Market.{' '}
            </p>
          </div>
        </div>
      </div>

      <div id={styles.franchises_wrapper}>
        <div className="inner">
          <img id={styles.bg} src={imageBgfranchise} alt="Background illustration" />

          <div id={styles.franchises_images}>
            <div className="grid">
              <div className="col_3">
                <div className={styles.img_wrapper}>
                  <img className={styles.desktop} src={imageStreetcart} alt="Street cart" />
                  <img className={styles.mobile} src={imageStreetcartmobile} alt="Street cart" />
                </div>
                <div className={styles.data}>
                  <h3>Street Cart</h3>
                  <p className={styles.qty}>
                    <span>2x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>
                        Free digital access to the upcoming Monster Chompers graphic novel
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.img_wrapper}>
                  <img className={styles.desktop} src={imageFoodtruck} alt="Food truck" />
                  <img className={styles.mobile} src={imageFoodtruckmobile} alt="Food truck" />
                </div>
                <div className={styles.data}>
                  <h3>Food truck</h3>
                  <p className={styles.qty}>
                    <span>5x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>
                        Free digital access to the upcoming Monster Chompers graphic novel
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.img_wrapper}>
                  <img className={styles.desktop} src={imageMonsterbar} alt="Monster Bar" />
                  <img className={styles.mobile} src={imageMonsterbarmobile} alt="Monster Bar" />
                </div>
                <div className={styles.data}>
                  <h3>Monster Bar</h3>
                  <p className={styles.qty}>
                    <span>7x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>Physical copy of the upcoming Monster Chompers graphic novel</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.img_wrapper}>
                  <img className={styles.desktop} src={imageBigrestaurant} alt="Big Restaurant" />
                  <img
                    className={styles.mobile}
                    src={imageBigrestaurantmobile}
                    alt="Big Restaurant"
                  />
                </div>
                <div className={styles.data}>
                  <h3>Big restaurant</h3>
                  <p className={styles.qty}>
                    <span>10x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>NFT naming rights of the restaurant</span>
                    </li>
                    <li>
                      <span>Physical copy of the upcoming Monster Chompers graphic novel</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id={styles.franchises_list}>
          <div className="inner">
            <div className="grid">
              <div className="col_3">
                <div className={styles.data}>
                  <h3>Street Cart</h3>
                  <p className={styles.qty}>
                    <span>2x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>
                        Free digital access to the upcoming Monster Chompers graphic novel
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.data}>
                  <h3>Food truck</h3>
                  <p className={styles.qty}>
                    <span>5x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>
                        Free digital access to the upcoming Monster Chompers graphic novel
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.data}>
                  <h3>Monster Bar</h3>
                  <p className={styles.qty}>
                    <span>7x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>Physical copy of the upcoming Monster Chompers graphic novel</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col_3">
                <div className={styles.data}>
                  <h3>Big restaurant</h3>
                  <p className={styles.qty}>
                    <span>10x</span> Monster Chompers
                  </p>
                  <ul>
                    <li>
                      <span>Sales commissions of NFT secondary sales</span>
                    </li>
                    <li>
                      <span>NFT naming rights of the restaurant</span>
                    </li>
                    <li>
                      <span>Physical copy of the upcoming Monster Chompers graphic novel</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Franchises;
